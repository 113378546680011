import { all } from 'redux-saga/effects'
import leads from './leads/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import accounts from './accounts/sagas'
import invoice from './invoice/sagas'
import enquiry from './enquiry/sagas'
import dailyreports from './dailyreports/sagas'
import renewals from './renewals/sagas'
import services from './services/sagas'
import singleaccounts from './singleaccounts/sagas'
import dashboard from './dashboard/sagas'
import products from './products/sagas'

export default function* rootSaga() {
  yield all([
    leads(),
    user(),
    menu(),
    settings(),
    staff(),
    accounts(),
    invoice(),
    enquiry(),
    dailyreports(),
    renewals(),
    services(),
    singleaccounts(),
    dashboard(),
    products(),
  ])
}
