/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  productsListInfo: null,
  searchproductname: null,
  displayAddProductsNameDrawer: false,
  displayUpdateProductsNameDrawer: false,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, displayAddProductsNameDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddProductsNameDrawer: false }
      case actions.SHOW_UPDATE:
      return {
        ...state,
        selectedData: action.payload.products,
        displayUpdateProductsNameDrawer: true,
      }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateProductsNameDrawer: false }
    default:
      return state
  }
}
