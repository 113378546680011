import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallleads() {
  return apiClient
    .get(`${BASE_URL}/getleads`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadleadnotes(payload) {
  return apiClient
    .get(`${BASE_URL}/getleadnoteinfo/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function converttoaccounts(payload) {
  return apiClient
    .put(`${BASE_URL}/convertleadtoaccountsinfo/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveleadnote(payload) {
  return apiClient
    .post(`${BASE_URL}/saveleadnoteinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addleadfollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/addleadfollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadleadfilter(payload) {
  return apiClient
    .post(`${BASE_URL}/loadleadfilter`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadleadowners() {
  return apiClient
    .get(`${BASE_URL}/getleadowners`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveleads(payload) {
  return apiClient
    .post(`${BASE_URL}/saveleadinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editleads(_id, payload) {
  return apiClient
    .put(`${BASE_URL}/editleadinfo/${_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeleadowner(payload) {
  return apiClient
    .post(`${BASE_URL}/changeleadowner`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteleads(_id) {
  return apiClient
    .put(`${BASE_URL}/deleteleadinfo/${_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
