import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadall() {
  return apiClient
    .get(`${BASE_URL}/loadinvoice`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadinvoicedetail(payload) {
  return apiClient
    .get(`${BASE_URL}/getinvoicedetail/${payload?.invoiceId}/${payload?.type}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadpaymentdetail(payload) {
  return apiClient
    .get(`${BASE_URL}/getpaymentdetail/${payload?.invoiceId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createItem(payload) {
  return apiClient
    .post(`${BASE_URL}/createinvoice/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function load_proforma(payload) {
  return apiClient
    .post(`${BASE_URL}/getproforma/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function load_invoice(payload) {
  return apiClient
    .post(`${BASE_URL}/getinvoice/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removeproforma(payload) {
  return apiClient
    .post(`${BASE_URL}/deleteproforma`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function removeinvoice(payload) {
  return apiClient
    .post(`${BASE_URL}/deleteinvoice`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addfollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/addinvoicefollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
