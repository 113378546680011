import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadalldailyreports, savedailyreport } from 'api/dailyreports'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadalldailyreports)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        dailyReportData: response?.data?.dailyreportInfo,
      },
    })
  }
}

export function* ADD_DAILY_REPORTS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(savedailyreport, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.ADD_DAILY_REPORTS, ADD_DAILY_REPORTS),
  ])
}
