import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadleadfollowup,
  updateleadfollowup,
  loadservicefollowup,
  updateservicefollowup,
  loadinvoicefollowup,
  updateinvoicefollowup,
  loadaccountsfollowup,
  updateaccountsfollowup,
} from 'api/dashboard'
import actions from './actions'

export function* LOAD_LEAD_FOLOWUP() {
  const { response } = yield call(loadleadfollowup)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        leadfollowupinfo: response?.data?.leadfollowupinfo,
      },
    })
  }
}

export function* LOAD_SERVICE_FOLOWUP() {
  const { response } = yield call(loadservicefollowup)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        servicefollowupinfo: response?.data?.servicefollowupinfo,
      },
    })
  }
}

export function* LOAD_INVOICE_FOLOWUP() {
  const { response } = yield call(loadinvoicefollowup)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicefollowupinfo: response?.data?.invoicefollowupinfo,
      },
    })
  }
}

export function* LOAD_ACCOUNTS_FOLOWUP() {
  const { response } = yield call(loadaccountsfollowup)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountsfollowupinfo: response?.data?.accountsfollowupinfo,
      },
    })
  }
}

export function* UPDATE_LEAD_FOLLOWUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: true,
    },
  })
  const { response } = yield call(updateleadfollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayLeadFollowup: false,
        adding: false,
        leadfollowupinfo: response?.data?.leadfollowupinfo,
      },
    })
  }
}
export function* UPDATE_SERVICE_FOLLOWUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: true,
    },
  })
  const { response } = yield call(updateservicefollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayServiceFollowup: false,
        adding: false,
        servicefollowupinfo: response?.data?.servicefollowupinfo,
      },
    })
  }
}
export function* UPDATE_INVOICE_FOLLOWUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: true,
    },
  })
  const { response } = yield call(updateinvoicefollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayInvoiceFollowup: false,
        adding: false,
        invoicefollowupinfo: response?.data?.invoicefollowupinfo,
      },
    })
  }
}

export function* UPDATE_ACCOUNTS_FOLLOWUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      adding: true,
    },
  })
  const { response } = yield call(updateaccountsfollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAccountsFollowup: false,
        adding: false,
        accountsfollowupinfo: response?.data?.accountsfollowupinfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ACCOUNTS_FOLOWUP, LOAD_ACCOUNTS_FOLOWUP),
    takeLatest(actions.UPDATE_INVOICE_FOLLOWUP, UPDATE_INVOICE_FOLLOWUP),
    takeLatest(actions.LOAD_INVOICE_FOLOWUP, LOAD_INVOICE_FOLOWUP),
    takeLatest(actions.LOAD_LEAD_FOLOWUP, LOAD_LEAD_FOLOWUP),
    takeLatest(actions.UPDATE_LEAD_FOLLOWUP, UPDATE_LEAD_FOLLOWUP),
    takeLatest(actions.LOAD_SERVICE_FOLOWUP, LOAD_SERVICE_FOLOWUP),
    takeLatest(actions.UPDATE_SERVICE_FOLLOWUP, UPDATE_SERVICE_FOLLOWUP),
    takeLatest(actions.UPDATE_ACCOUNTS_FOLLOWUP, UPDATE_ACCOUNTS_FOLLOWUP),
  ])
}
