import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Switch, Button } from 'antd'
// import IndividualAccountsAction from 'redux/singleaccounts/actions'
import AccountsAction from 'redux/accounts/actions'

const mapStateToProps = ({ dispatch, singleaccounts }) => ({
  dispatch,
  singleaccounts,
})

const StoreSettings = ({ dispatch, apiRes, singleaccounts, intl: { formatMessage } }) => {
  const path = window.location.pathname

  const userId = path.replace('/manage/bookaccounts/', '')

  console.log('userId', userId)

  const [form] = Form.useForm()

  const AccountDatas = apiRes

  const onFinish = values => {
    dispatch({
      type: AccountsAction.UPDATE_ACCOUNTS,
      payload: {
        updatedData: values,
        _id: AccountDatas?._id,
      },
    })
  }

  useEffect(() => {
    dispatch({
      type: AccountsAction.LOAD_ALL,
    })
  }, [dispatch])

  useEffect(() => {
    if (AccountDatas) {
      form.setFieldsValue({
        status: Number(AccountDatas?.status),
        business_name: AccountDatas?.business_name,
        contact_name: AccountDatas?.contact_name,
        emailid: AccountDatas?.emailid,
        mobile: AccountDatas?.mobile,
        phone: AccountDatas?.phone,
        addressline1: AccountDatas?.addressline1,
        addressline2: AccountDatas?.addressline2,
        addressline3: AccountDatas?.addressline3,
        landmark: AccountDatas?.landmark,
        city: AccountDatas?.city,
        state: AccountDatas?.state,
        country: AccountDatas?.country,
        pincode: AccountDatas?.pincode,
        gst_number: AccountDatas?.gst_number,
        website_url: AccountDatas?.website_url,
      })
    } // eslint-disable-next-line
  }, [form, AccountDatas])

  const toInputLowercase = e => {
    e.target.value = e.target.value.toLowerCase()
  }

  return (
    <div>
      <Form
        form={form}
        className="mb-4"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          status: Number(AccountDatas?.status),
          business_name: AccountDatas?.business_name,
          contact_name: AccountDatas?.contact_name,
          emailid: AccountDatas?.emailid,
          mobile: AccountDatas?.mobile,
          phone: AccountDatas?.phone,
          addressline1: AccountDatas?.addressline1,
          addressline2: AccountDatas?.addressline2,
          addressline3: AccountDatas?.addressline3,
          landmark: AccountDatas?.landmark,
          city: AccountDatas?.city,
          state: AccountDatas?.state,
          country: AccountDatas?.country,
          pincode: AccountDatas?.pincode,
          gst_number: AccountDatas?.gst_number,
          website_url: AccountDatas?.website_url,
        }}
        hideRequiredMark
      >
        <div className="row">
          <div className="col-md-6">
            <Form.Item
              name="business_name"
              label={formatMessage({ id: 'form.label.business_name' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.business_name' })}
                maxLength={64}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="contact_name"
              label={formatMessage({ id: 'form.label.contact_name' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.contact_name' })}
                maxLength={32}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="emailid" label={formatMessage({ id: 'form.label.emailid' })}>
              <Input
                type="email"
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.emailid' })}
                maxLength={64}
                onInput={toInputLowercase}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="mobile"
              label={formatMessage({ id: 'form.label.mobile' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                size="large"
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="phone" label={formatMessage({ id: 'form.label.phone' })}>
              <Input
                placeholder={formatMessage({ id: 'form.placeholder.phone' })}
                size="large"
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="addressline1"
              label={formatMessage({ id: 'form.label.addressline1' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.addressline1' })}
                maxLength={45}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="addressline2" label={formatMessage({ id: 'form.label.addressline2' })}>
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.addressline2' })}
                maxLength={45}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="addressline3" label={formatMessage({ id: 'form.label.addressline3' })}>
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.addressline3' })}
                maxLength={45}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item name="landmark" label={formatMessage({ id: 'form.label.landmark' })}>
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.landmark' })}
                maxLength={32}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="city"
              label={formatMessage({ id: 'form.label.city' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.city' })}
                maxLength={32}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="state"
              label={formatMessage({ id: 'form.label.state' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.state' })}
                maxLength={32}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="country"
              label={formatMessage({ id: 'form.label.country' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.country' })}
                maxLength={32}
                readOnly
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              name="pincode"
              label={formatMessage({ id: 'form.label.pincode' })}
              rules={[{ required: true, message: formatMessage({ id: 'form.error.required' }) }]}
            >
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.pincode' })}
                maxLength={32}
                readOnly
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item name="website_url" label={formatMessage({ id: 'form.label.website_url' })}>
              <Input
                placeholder={formatMessage({
                  id: 'form.placeholder.website_url',
                })}
                size="large"
                readOnly
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item name="gst_number" label={formatMessage({ id: 'form.label.gst_number' })}>
              <Input
                size="large"
                placeholder={formatMessage({ id: 'form.placeholder.gst_number' })}
                maxLength={32}
                readOnly
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item
              name="status"
              label={formatMessage({ id: 'form.label.accountStatus' })}
              valuePropName="checked"
            >
              <Switch
                checkedChildren={formatMessage({ id: 'action.active' })}
                unCheckedChildren={formatMessage({ id: 'action.inactive' })}
              />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item className="text-right">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={singleaccounts?.updating}
              >
                <strong>{formatMessage({ id: 'action.update' })}</strong>
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreSettings))
