import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loaddomainlist() {
  return apiClient
    .get(`${BASE_URL}/getdomainlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadhostinglist() {
  return apiClient
    .get(`${BASE_URL}/gethostinglist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function domainlist() {
  return apiClient
    .get(`${BASE_URL}/domainlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savedomainname(payload) {
  return apiClient
    .post(`${BASE_URL}/savedomaininfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function savehosting(payload) {
  return apiClient
    .post(`${BASE_URL}/savehosting`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatehosting(payload) {
  return apiClient
    .post(`${BASE_URL}/updatehostinginfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updatedomainname(payload) {
  return apiClient
    .post(`${BASE_URL}/updatedomaininfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function emaillist() {
  return apiClient
    .get(`${BASE_URL}/getemailserverlist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveemaillist(payload) {
  return apiClient
    .post(`${BASE_URL}/saveemaillist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateemaillist(payload) {
  return apiClient
    .post(`${BASE_URL}/updateemaillist`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


