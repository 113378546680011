import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadall,
  loadinvoicedetail,
  loadpaymentdetail,
  createItem,
  load_proforma,
  load_invoice,
  removeproforma,
  removeinvoice,
  addfollowup,
} from 'api/invoice'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadall)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicelist: response?.data?.invoicelistinfo,
      },
    })
  }
}

export function* REMOVE_PROFORMA({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(removeproforma, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicelist: response?.data?.invoicelistinfo,
        displayRemoveInvoiceModal: false,
      },
    })
  }
}

export function* REMOVE_INVOICE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(removeinvoice, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicelist: response?.data?.invoicelistinfo,
        displayRemoveInvoiceModal: false,
      },
    })
  }
}

export function* LOAD_INVOICE_DETAIL({ payload }) {
  const { response } = yield call(loadinvoicedetail, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicedetail: response?.data?.invoicedetailinfo,
      },
    })
  }
}

export function* LOAD_PAYMENT_DETAIL({ payload }) {
  const { response } = yield call(loadpaymentdetail, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        paymentdetail: response?.data?.paymentdetailinfo[0],
      },
    })
  }
}

export function* VIEW_PO({ payload }) {
  const { response } = yield call(load_proforma, payload)
  if (response) {
    yield call(LOAD_ALL)
    yield put({
      type: actions.SET_STATE,
      payload: {
        proformapdfLocationUrl: response?.data?.proformapdfLocationUrl,
        openproforma: true,
      },
    })
  }
}

export function* VIEW_INVOICES({ payload }) {
  const { response } = yield call(load_invoice, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicepdfLocationUrl: response?.data?.invoicepdfLocationUrl,
      },
    })
  }
}

export function* CREATE_INVOICE_ITEM({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(createItem, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayCreateInvoiceDrawer: false,
        invoicelist: response?.data?.invoicelistinfo,
      },
    })
  }
}

export function* ADD_FOLLOWUP({ payload }) {
  const { response } = yield call(addfollowup, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayFollowupDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.ADD_FOLLOWUP, ADD_FOLLOWUP),
    takeLatest(actions.REMOVE_PROFORMA, REMOVE_PROFORMA),
    takeLatest(actions.REMOVE_INVOICE, REMOVE_INVOICE),
    takeLatest(actions.VIEW_PO, VIEW_PO),
    takeLatest(actions.VIEW_INVOICES, VIEW_INVOICES),
    takeLatest(actions.LOAD_INVOICE_DETAIL, LOAD_INVOICE_DETAIL),
    takeLatest(actions.LOAD_PAYMENT_DETAIL, LOAD_PAYMENT_DETAIL),
    takeLatest(actions.CREATE_INVOICE_ITEM, CREATE_INVOICE_ITEM),
  ])
}
