import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadProductsList() {
  return apiClient
    .get(`${BASE_URL}/getproductslist`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveProducts(payload) {
  return apiClient
    .post(`${BASE_URL}/saveproducts/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateproducts(payload) {
  return apiClient
    .post(`${BASE_URL}/updateproductsinfo/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}