import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadsingleaccount(payload) {
  return apiClient
    .post(`${BASE_URL}/loadsingleaccount`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateaccounts(_id, payload) {
  return apiClient
    .put(`${BASE_URL}/editaccountsinfo/${_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
