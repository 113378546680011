import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadsingleaccount } from 'api/singleaccounts'
import actions from './actions'

export function* LOAD_SINGLE_ACCOUNTS({ payload }) {
  const { response } = yield call(loadsingleaccount, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        IndividualAccountData: response?.data?.IndividualAccountData[0],
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_SINGLE_ACCOUNTS, LOAD_SINGLE_ACCOUNTS)])
}
