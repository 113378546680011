const actions = {
  SET_STATE: 'products/SET_STATE',

  LOAD_ALL: 'products/LOAD_ALL',

  SHOW_CREATE: 'products/SHOW_CREATE',
  HIDE_CREATE: 'products/HIDE_CREATE',
  SAVE_PRODUCTS_INFO: 'products/SAVE_PRODUCTS_INFO',

  SHOW_UPDATE: 'products/SHOW_UPDATE',
  HIDE_UPDATE: 'products/HIDE_UPDATE',
  UPDATE_PRODUCTS_INFO: 'products/UPDATE_PRODUCTS_INFO',
}

export default actions
