const actions = {
  SET_STATE: 'dashboard/SET_STATE',

  LOAD_INVOICE_FOLOWUP: 'dashboard/LOAD_INVOICE_FOLOWUP',
  LOAD_LEAD_FOLOWUP: 'dashboard/LOAD_LEAD_FOLOWUP',
  SHOW_LEAD_FOLLOWUP: 'dashboard/SHOW_LEAD_FOLLOWUP',
  SHOW_SERVICE_FOLLOWUP: 'dashboard/SHOW_SERVICE_FOLLOWUP',
  HIDE_LEAD_FOLLOWUP: 'dashboard/HIDE_LEAD_FOLLOWUP',
  HIDE_SERVICE_FOLLOWUP: 'dashboard/HIDE_SERVICE_FOLLOWUP',
  UPDATE_LEAD_FOLLOWUP: 'dashboard/UPDATE_LEAD_FOLLOWUP',
  UPDATE_SERVICE_FOLLOWUP: 'dashboard/UPDATE_SERVICE_FOLLOWUP',
  LOAD_SERVICE_FOLOWUP: 'dashboard/LOAD_SERVICE_FOLOWUP',
  SHOW_INVOICE_FOLLOWUP: 'dashboard/SHOW_INVOICE_FOLLOWUP',
  HIDE_INVOICE_FOLLOWUP: 'dashboard/HIDE_INVOICE_FOLLOWUP',
  UPDATE_INVOICE_FOLLOWUP: 'dashboard/UPDATE_INVOICE_FOLLOWUP',
  LOAD_ACCOUNTS_FOLOWUP: 'dashboard/LOAD_ACCOUNTS_FOLOWUP',
  SHOW_ACCOUNTS_FOLLOWUP: 'dashboard/SHOW_ACCOUNTS_FOLLOWUP',
  HIDE_ACCOUNTS_FOLLOWUP: 'dashboard/HIDE_ACCOUNTS_FOLLOWUP',
  UPDATE_ACCOUNTS_FOLLOWUP: 'dashboard/UPDATE_ACCOUNTS_FOLLOWUP',
}

export default actions
