import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadProductsList,
  saveProducts,
  updateproducts
} from 'api/products'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadProductsList)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        productsListInfo: response?.data?.productslist,
      },
    })
  }
}

export function* SAVE_PRODUCTS_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(saveProducts, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_PRODUCTS_INFO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateproducts, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
      displayUpdateProductsNameDrawer: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield call(LOAD_ALL)
  }
}



export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.SAVE_PRODUCTS_INFO, SAVE_PRODUCTS_INFO),
    takeLatest(actions.UPDATE_PRODUCTS_INFO, UPDATE_PRODUCTS_INFO),
  ])
}
