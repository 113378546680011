const actions = {
  SET_STATE: 'invoice/SET_STATE',
  LOAD_ALL: 'invoice/LOAD_ALL',
  VIEW_PO: 'invoice/VIEW_PO',
  VIEW_INVOICES: 'invoice/VIEW_INVOICES',
  LOAD_INVOICE_DETAIL: 'invoice/LOAD_INVOICE_DETAIL',
  LOAD_PAYMENT_DETAIL: 'invoice/LOAD_PAYMENT_DETAIL',
  ADD_ITEM: 'invoice/ADD_ITEM',
  HIDE_ITEM: 'invoice/HIDE_ITEM',
  CREATE_INVOICE_ITEM: 'invoice/CREATE_INVOICE_ITEM',
  SHOW_DELETE: 'invoice/SHOW_DELETE',
  HIDE_DELETE: 'invoice/HIDE_DELETE',
  REMOVE_PROFORMA: 'invoice/REMOVE_PROFORMA',
  REMOVE_INVOICE: 'invoice/REMOVE_INVOICE',
  SHOW_FOLLOWUP: 'invoice/SHOW_FOLLOWUP',
  HIDE_FOLLOWUP: 'invoice/HIDE_FOLLOWUP',
  ADD_FOLLOWUP: 'invoice/ADD_FOLLOWUP',
}

export default actions
