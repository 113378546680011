import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadallaccounts() {
  return apiClient
    .get(`${BASE_URL}/getaccounts`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateaccounts(_id, payload) {
  return apiClient
    .put(`${BASE_URL}/editaccountsinfo/${_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadaccountnotes(payload) {
  return apiClient
    .get(`${BASE_URL}/getaccountnoteinfo/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function saveaccountsnotes(payload) {
  return apiClient
    .post(`${BASE_URL}/saveaccountnoteinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeaccountmanager(payload) {
  return apiClient
    .post(`${BASE_URL}/changeaccountmanager`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changeaccountsassignedto(payload) {
  return apiClient
    .post(`${BASE_URL}/changeaccountsassignedto`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function addaccountsfollowup(payload) {
  return apiClient
    .post(`${BASE_URL}/addaccountsfollowup`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
